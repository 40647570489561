'use client'

import React from 'react'
import { cn } from '@/utils/ui'
import { Media } from '@/components/core/Media'
import { MotionInfinite } from '@/components/motion/MotionInfinite'
import type { HisTrackHeroBlock as HisTrackHeroProps } from '@/payload-types'

type Props = {
  gallery: HisTrackHeroProps['media']['gallery']
}

export function GalleryTrack({ gallery }: Props): React.ReactNode {
  return (
    <div className="relative w-full overflow-hidden">
      {/* White Background Strip */}
      <div className="absolute right-0 bottom-0 left-0 h-[250px] bg-white" />

      {/* Scrolling Gallery */}
      <div className="relative z-10">
        <MotionInfinite direction="left" speed={60} repeat={2} gap={4}>
          <div className="flex gap-2">
            {gallery.map((item, index: number) => (
              <div
                key={`image-${index}`}
                className={cn(
                  'relative h-[500px] shrink-0 overflow-hidden rounded-sm bg-white',
                  // Width based on aspect ratio
                  item.aspectRatio === 'square' && 'w-[500px]',
                  item.aspectRatio === 'portrait' && 'w-[375px]', // 3:4 ratio
                  item.aspectRatio === 'landscape' && 'w-[666px]', // 4:3 ratio
                )}
              >
                <Media
                  resource={item.image}
                  fill
                  sizes="(max-width: 640px) 100vw, 800px"
                  priority={index < 2}
                  className="object-cover"
                />
              </div>
            ))}
          </div>
        </MotionInfinite>
      </div>
    </div>
  )
}

'use client'

import React from 'react'
import type { ServicesHeroBlock as ServicesHeroProps } from '@/payload-types'
import { Title } from '@/components/ui/Title'
import { RichText } from '@/components/core/RichText'
import { cn } from '@/utils/ui'

type Props = ServicesHeroProps & {
  className?: string
}

export const ServicesHero: React.FC<Props> = (props) => {
  const { content, className } = props

  if (!content?.title || !content?.description) {
    return null
  }

  return (
    <section
      className={cn(
        'bg-brand-dark-bg col-[bleed-start/bleed-end] grid grid-cols-subgrid py-16 text-white sm:py-24 md:py-32',
        className,
      )}
    >
      <div className="col-[content-start/content-end] grid grid-cols-1 gap-x-8 gap-y-12 md:grid-cols-2">
        <div className="flex flex-col space-y-4">
          <div className="flex items-center justify-between text-sm">
            <div className="font-light">{content.eyebrow || 'Services'}</div>
            {content.servicesCount && (
              <div className="flex items-center font-semibold">
                <svg
                  className="mr-2 h-3 w-3 fill-current"
                  viewBox="0 0 12 12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m6 0 .87252 3.89355 3.37008-2.13619-2.13615 3.37012L12 6l-3.89355.87252 2.13615 3.37008-3.37008-2.13615L6 12l-.87252-3.89355-3.37012 2.13615 2.13619-3.37008L0 6l3.89355-.87252-2.13619-3.37012 3.37012 2.13619L6 0Z" />
                </svg>
                {content.servicesCount} Services available
              </div>
            )}
          </div>
          <Title el="h1" size="display-small">
            {content.title}
          </Title>
        </div>
        <div className="flex flex-col justify-end space-y-4 md:items-end">
          <div className="max-w-xl text-lg font-light md:text-xl lg:text-2xl">
            {content.description && <RichText data={content.description} enableGutter={false} />}
          </div>
          {content.scrollCta && (
            <div className="text-sm font-semibold opacity-75">{content.scrollCta}</div>
          )}
        </div>
      </div>
    </section>
  )
}

'use client'

import React, { useState } from 'react'
import { Media } from '@/components/core/Media'
import type { Media as MediaType } from '@/payload-types'
import { VideoDialog } from './VideoDialog.client'

type Props = {
  previewImage: MediaType
  _previewVideo: string
}

export function PreviewVideo({ previewImage, _previewVideo }: Props): React.ReactNode {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const handlePreviewClick = () => {
    setIsDialogOpen(true)
  }

  const handleDialogClose = () => {
    setIsDialogOpen(false)
  }

  return (
    <>
      <div className="absolute right-8 bottom-8 z-20">
        <div className="flex flex-col gap-2 rounded-2xl bg-white/[0.16] p-2 text-center text-white backdrop-blur-lg">
          <div 
            className="group relative aspect-video w-[380px] cursor-pointer overflow-hidden rounded-2xl"
            onClick={handlePreviewClick}
          >
            <Media
              resource={previewImage}
              className="h-full w-full object-cover"
              htmlElement="div"
              priority
            />
            {/* Play Button Overlay */}
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="flex h-10 w-10 items-center justify-center rounded-full bg-white transition-transform group-hover:scale-110">
                <svg
                  className="h-4 w-4"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.5 4.5v15a1 1 0 001.47.87l12-7.5a1 1 0 000-1.74l-12-7.5A1 1 0 006.5 4.5z"
                    fill="currentColor"
                    className="text-neutral-900"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="text-xs font-medium uppercase">Watch Video</div>
        </div>
      </div>

      {/* Video Dialog */}
      <VideoDialog 
        isOpen={isDialogOpen} 
        onClose={handleDialogClose} 
      />
    </>
  )
}

'use client'

import React, { useEffect, useState, useRef } from 'react'
import type { StudioHeroBlock as StudioHeroProps, Media as MediaType } from '@/payload-types'
import { Media } from '@/components/core/Media'
import { RichText } from '@/components/core/RichText'
import { MotionParallax } from '@/components/motion/MotionParallax'
import { cn } from '@/utils/ui'

type Props = {
  className?: string
} & StudioHeroProps

const isMedia = (value: string | MediaType): value is MediaType => {
  return typeof value === 'object' && value !== null && 'id' in value
}

type IntensityType = 'subtle' | 'balanced' | 'dramatic'

export const StudioHero: React.FC<Props> = (props) => {
  const { content, media, settings = {}, className } = props
  const [scrollOpacity, setScrollOpacity] = useState(0)
  const sectionRef = useRef<HTMLElement>(null)

  useEffect(() => {
    const handleScroll = () => {
      if (!sectionRef.current) return

      const rect = sectionRef.current.getBoundingClientRect()
      const sectionHeight = rect.height
      const scrollPosition = window.scrollY

      const scrollProgress = Math.min(Math.max(0, scrollPosition / sectionHeight), 1)

      const adjustedOpacity = Math.min(scrollProgress * 2, 0.85)

      setScrollOpacity(adjustedOpacity)
    }

    window.addEventListener('scroll', handleScroll)
    handleScroll()

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  if (!content?.title || !content?.description || !media?.image) {
    return null
  }

  const motion = settings?.motion || 'none'

  const getIntensity = (): IntensityType => {
    if (!settings?.intensity) return 'balanced'

    if (
      settings.intensity === 'subtle' ||
      settings.intensity === 'balanced' ||
      settings.intensity === 'dramatic'
    ) {
      return settings.intensity
    }

    return 'balanced'
  }

  const intensity = getIntensity()

  const getParallaxSettings = () => {
    let depth = 15

    if (intensity === 'subtle') depth = 7
    else if (intensity === 'balanced') depth = 15
    else if (intensity === 'dramatic') depth = 25

    const overflowHeightClass =
      intensity === 'subtle' ? 'h-[115%]' : intensity === 'dramatic' ? 'h-[150%]' : 'h-[130%]'

    const insetYClass =
      intensity === 'subtle'
        ? '-inset-y-[7.5%]'
        : intensity === 'dramatic'
          ? '-inset-y-[25%]'
          : '-inset-y-[15%]'

    return {
      depth: motion === 'up' ? -depth : depth,
      overflowHeightClass,
      insetYClass,
    }
  }

  const { depth, overflowHeightClass, insetYClass } = getParallaxSettings()
  const imageData = isMedia(media.image) ? media.image : null

  if (!imageData) return null

  return (
    <div
      ref={sectionRef as React.RefObject<HTMLDivElement>}
      className={cn(
        'relative col-[bleed-start/bleed-end] h-[150vh] bg-zinc-950 text-stone-50',
        className,
      )}
    >
      <div className="relative z-10 container mx-auto h-full px-4 pb-24">
        <div className="flex h-full flex-col pt-[15vh]">
          <div className="mt-16 -ml-4 md:mt-20 md:-ml-6 lg:-ml-12">
            {content.eyebrow && (
              <div className="mb-4 flex items-center">
                <div className="mr-3 h-2 w-2 rounded-full bg-white"></div>
                <p className="text-sm tracking-wider text-white/80 uppercase">{content.eyebrow}</p>
              </div>
            )}
            <h1 className="font-secondary max-w-[90%] text-6xl leading-[0.85] font-bold tracking-tighter text-white md:text-9xl lg:text-[10rem] xl:text-[11rem]">
              {content.title}
            </h1>
          </div>

          <div className="mt-20 grid grid-cols-12 gap-8 md:mt-28 md:gap-12 lg:mt-48 lg:gap-24">
            <div className="col-span-12 md:col-span-3">
              <ul className="mb-8 list-none md:mb-0">
                <li className="mb-6">
                  <button className="flex cursor-pointer items-center">Case Studies</button>
                </li>
                <li className="mb-6">
                  <button className="flex cursor-pointer items-center">Recent Outcomes</button>
                </li>
                <li className="mb-6">
                  <button className="flex cursor-pointer items-center">Partnership</button>
                </li>
                <li className="mb-6">
                  <button className="flex cursor-pointer items-center">Why Work with Us</button>
                </li>
                <li className="mb-6">
                  <button className="flex cursor-pointer items-center">Engagement Models</button>
                </li>
              </ul>
            </div>

            <div className="hidden md:col-span-2 md:block lg:col-span-3"></div>

            <div className="col-span-12 text-[1.63rem] leading-8 font-medium md:col-span-7 lg:col-span-6">
              <RichText data={content.description} />

              <div className="mt-24">
                <button className="group relative inline-flex cursor-pointer items-center justify-between pb-1.5 text-xl font-bold">
                  <span className="flex border-b border-white transition-colors group-hover:border-white/80">
                    Transform with Us
                  </span>
                  <div className="relative -mr-3.5 ml-3.5 h-10 w-10">
                    <svg
                      className="absolute top-0 bottom-0 left-0 h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="12" cy="12" fill="currentColor" height="24" r="12" />
                    </svg>
                    <svg
                      className="absolute top-1/2 left-1/2 h-2/4 w-2/4 -translate-x-1/2 -translate-y-1/2 text-white mix-blend-difference"
                      fill="rgb(255, 255, 255)"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M8.55 3.31H2.87v-.9h6.58V9h-.9V3.3Z" fill="rgb(255, 255, 255)" />
                      <path
                        d="m2.56 8.67 6.12-6.13.64.64-6.13 6.13-.63-.64Z"
                        fill="rgb(255, 255, 255)"
                      />
                    </svg>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="absolute inset-0">
        <div className="relative h-full w-full overflow-hidden">
          {motion === 'up' || motion === 'down' ? (
            <MotionParallax depth={depth} className="absolute inset-0 h-full w-full">
              <div className={`absolute ${insetYClass} right-0 left-0 ${overflowHeightClass}`}>
                <Media
                  resource={imageData}
                  fill
                  priority
                  quality={99}
                  sizes="100vw"
                  className="h-full w-full object-cover object-center"
                />
              </div>
            </MotionParallax>
          ) : motion === 'fixed' ? (
            <div className="fixed inset-0 h-full w-full">
              <Media
                resource={imageData}
                fill
                priority
                quality={99}
                sizes="100vw"
                className="h-full w-full object-cover object-center"
              />
            </div>
          ) : (
            <Media
              resource={imageData}
              fill
              priority
              quality={99}
              sizes="100vw"
              className="h-full w-full object-cover object-center"
            />
          )}

          <div className="absolute inset-0 bg-gradient-to-b from-zinc-950/20 via-zinc-950/60 to-zinc-950/95"></div>

          <div
            className="pointer-events-none absolute inset-0 bg-zinc-950"
            style={{ opacity: scrollOpacity }}
          ></div>
        </div>
      </div>
    </div>
  )
}

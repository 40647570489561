'use client'

import React from 'react'

type DialogProps = {
  isOpen: boolean
  onClose: () => void
}

export function VideoDialog({ isOpen, onClose }: DialogProps): React.ReactNode {
  if (!isOpen) return null

  return (
    <div 
      className="fixed inset-0 z-50 flex items-center justify-center bg-black/70"
      onClick={onClose}
    >
      <div 
        className="bg-white p-6 rounded-lg max-w-md w-full text-black"
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className="text-xl font-bold mb-4">Hello World</h2>
        <p>This is a simple dialog from a client component.</p>
        <button 
          className="mt-4 px-4 py-2 bg-black text-white rounded hover:bg-gray-800"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  )
}
